import React from 'react';
import styled from 'styled-components';

const BoxText = styled.div`
  background-color: white;
  width: 100%;
  padding: 1.5rem 0px;
  text-align: center;
  position: absolute;
  top: 0px;
  transform: translateY(-50%);
`;

export default function BoxedText() {
  return (
    <BoxText>
      <p className="t-text-3xl t-text-purple-1000 t-font-medium">
        Graduates Accepted <span className="t-text-blue-300">100%</span> to their First Choice of University
      </p>
    </BoxText>
  )
}
