import React from 'react'
import styled from 'styled-components';

const OffsetText = styled.p`
  z-index: -1;
  transform: translateY(-70px);
  opacity: 0;
  pointer-events: none;
`

const AnchorOffset = ({ id }) => {
  return (
    <OffsetText id={id} />
  )
}

export default AnchorOffset
