import { Link } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CalendarIcon from '../assets/images/icons/calendar-icon.svg';
import LocationIcon from '../assets/images/icons/location-icon.svg';

export default function EventPreview({ url, image, startTime, endTime, title, location }) {
  const gImage = getImage(image)
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const day = startDate.getDate();
  const month = startDate.toLocaleString('default', { month: 'short' });
  return (
    <Link to={url} className="t-contents">
      <div className="t-flex t-flex-col t-w-full sm:t-w-1/3 t-px-4 hover:t-shadow-custom t-transform t-transition-all t-scale-100 hover:t-scale-105">
        <GatsbyImage image={gImage} className="t-mb-5" alt="Event Image" />
        <div className="t-flex">
          <div className="t-flex t-flex-col t-items-center t-justify-center t-mr-6">
            <p className="t-font-bold t-text-blue-300 t-text-4xl t-mb-2">
              {day}
            </p>
            <p className="t-font-medium t-text-lg t-border-b t-border-blue-300 t-uppercase t-pb-1">
              {month}
            </p>
          </div>
          <div className="t-flex t-flex-col">
            <p className="t-font-medium t-text-2xl t-mb-3">
              {title}
            </p>
            <div className="t-mb-2 t-text-xs t-flex t-items-center">
              <figure className="t-flex t-justify-center t-w-6 t-mr-3">
                <img src={CalendarIcon} alt="Calendar Icon" />
              </figure>
              <span>
                <span>
                  {startDate.toLocaleTimeString('en-CA', { hour: '2-digit', minute: '2-digit' })}
                </span>
                <span className="t-mx-1">
                  -
                </span>
                <span>
                  {endDate.toLocaleTimeString('en-CA', { hour: '2-digit', minute: '2-digit' })}
                </span>
              </span>
            </div>
            <div className="t-mb-2 t-text-xs t-flex t-items-center">
              <figure className="t-flex t-justify-center t-w-6 t-mr-3">
                <img src={LocationIcon} alt="Location icon" />
              </figure>
              <p>
                {location}
              </p>
            </div>
          </div>

        </div>
      </div>
    </Link>
  )
}
