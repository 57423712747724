import React from 'react';
import { graphql, Link } from 'gatsby';
import Campus from '../assets/images/deran-campus.jpeg';
import Signature from '../assets/images/chloe-signature.svg';
import BoxedText from '../components/BoxedText';
import ApplicationSection from '../components/ApplicationSection';
import EventPreview from '../components/EventPreview';
import UpdatePreview from '../components/UpdatePreview';
import IBSchool from '../assets/images/ib-school.svg';
import IBSchoolBackground from '../assets/images/ib-school-bg.png';
import Title from '../typography/Title';
import styled from 'styled-components';
import { restructureEvent, restructureUpdate } from '../utils';
import AnchorOffset from '../components/AnchorOffset';

const Container = styled.div`
  background: url(${Campus});
  height: auto;
  width: 100vw;
  background-size: cover;
  background-attachment: fixed;
`;

const IBContainer = styled.div`
  background: url(${IBSchoolBackground});
  background-size: cover;
`;

export default function HomePage({ data }) {
  const { allSanityEvent, allSanityUpdate } = data;

  return (
    <Container className="t-pb-16">
      <section className="t-h-1/2screen t-bg-transparent t-flex t-items-start t-flex-col t-container t-mx-auto t-justify-center">
        <div className="t-relative">
          <p className="t-bg-purple-1000-alpha t-text-white t-text-8xl t-leading-none t-p-4 t-uppercase t-font-bold">
            Deran Academy
          </p>
          <div className="t-bg-purple-1000-alpha t-pl-8 t-w-max t-absolute t-mt-8">
            <Link to="/about#about-deran-academy">
              <p className="t-bg-alpha-white t-p-4 t-text-purple-1000 t-text-2xl xs:t-text-4xl t-w-max t-font-bold">
                ABOUT US
              </p>
            </Link>
          </div>
        </div>
      </section>
      <div className="t-container t-mx-auto t-pt-24 t-pb-12 t-relative t-flex t-flex-col t-justify-center t-items-center t-text-white t-text-center t-bg-purple-1000 lg:t-px-20 t-px-4">
        <BoxedText />
        <Title>
          Welcome Home
        </Title>
        <div className="t-h-px t-bg-white t-border t-border-white t-my-4 t-w-48"></div>
        <p className="t-text-white t-my-6">
          Congratulations on your educated decision to attend DERAN ACADEMY, where staff and teachers are energized and deeply committed to making a positive difference in your educational future. We accomplish this by setting high academic expectations for all our students and providing supportive systems to assure these expectations are met.
        </p>
        <p className="t-text-white t-my-6">
          On behalf of the faculty and staff at DERAN ACADEMY, it is my pleasure to welcome you. We will make every effort so that all students will graduate, are university ready and become successful members of society and are able to compete in an increasingly complex world in which they will work and live. The caring, dedicated and professional faculty and staff at DERAN ACADEMY will continue to encourage all students to set high goals for themselves and to reach for their dreams.
        </p>
        <p className="t-text-white t-my-6">
          In DERAN ACADEMY, there are diverse courses for students to select, including Mathematics, Science, Chemistry, Business, Art, and all of the academic classes needed for university. Our school also has a Shared Library for students. We are constantly working to make the school a better place for our students, staff and community members
        </p>
        <p className="t-text-white t-my-6">
          I sincerely hope you will take time to look through our Student Prospectus, our brochures and our web pages and learn about the opportunities available to all students who are members of our DERAN family. We believe our students’ years at DERAN ACADEMY are their opportunity to become scholars and champions!
        </p>
        <img width="382" src={Signature} className="t-my-8" alt="Chloe Signature" />
      </div>
      <div className="t-container t-mx-auto t-pt-12 t-pb-4 t-relative t-flex t-flex-col t-bg-white lg:t-px-20 t-px-4">
        <Title>
          DERAN ACADEMY IB MYP CANDIDATE
        </Title>
        <div className="t-h-px t-bg-gray-300 t-border t-border-gray-300 t-mt-4 t-mb-12 t-w-96"></div>
        <p className="t-my-3">
          Deran Academy is a candidate school for the International Middle Years Programme. and pursuing authorization as an IB World School.
        </p>
        <p className="t-my-3">
          IB World Schools share a common philosophy—a commitment to improve the teaching and learning of a diverse and inclusive community of students by delivering challenging, high quality programmes of international education that share a powerful vision.
        </p>
        <div className="t-flex t-items-center t-my-12">
          <img src={IBSchool} className="t-mr-6" alt="IB School" />
          <p className="t-text-2xl">IB World School</p>
        </div>
      </div>
      <IBContainer className="t-container t-mx-auto t-pt-12 t-pb-12 t-relative t-flex t-flex-col t-bg-white lg:t-px-20 t-px-4">
        <ApplicationSection />
      </IBContainer>
      <section className="t-flex t-flex-col t-container t-mx-auto t-py-20 t-bg-white lg:t-px-20 t-px-4">
        <div className="t-flex">
          <h3 className="t-text-4xl t-font-bold t-mb-4 t-mr-12">
            Activities & Events
          </h3>
          <AnchorOffset id="events" />
          <div className="t-h-px t-bg-gray-400 t-border-2 t-border-gray-300 t-my-4 t-flex-1"></div>
        </div>
        <div className="t-flex md:t--ml-4 t-w-full t-flex-wrap">
          {
            restructureEvent(allSanityEvent).map(event => (
              <EventPreview key={event.title} {...event} />
            ))
          }
        </div>
      </section>
      <section className="t-flex t-flex-col t-container t-mx-auto t-py-20 t-bg-white lg:t-px-20 t-px-4">
        <div className="t-flex">
          <h3 className="t-text-4xl t-font-bold t-mb-4 t-mr-12">
            News & Updates
          </h3>
          <AnchorOffset id="updates" />
          <div className="t-h-px t-bg-gray-400 t-border-2 t-border-gray-300 t-my-4 t-flex-1"></div>
        </div>
        <div className="t-flex md:t--ml-4 t--ml-4 t-w-full t-flex-wrap">
          {
            restructureUpdate(allSanityUpdate).map(update => (
              <UpdatePreview key={update.title} {...update} />
            ))
          }
        </div>
      </section>
    </Container>
  )
}

export const query = graphql`
  query RecentEventsQuery {
    allSanityEvent(sort: { fields: startTime, order: DESC }, limit: 3) {
      nodes {
        title
        startTime
        endTime
        location
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(fit: FILLMAX)
          }
        }
      }
    }
    allSanityUpdate(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        title
        date
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(fit: FILLMAX)
          }
        }
      }
    }
  }
`;
