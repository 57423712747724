import { Link } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";


export default function UpdatePreview({ url, image, date, title }) {
  const gImage = getImage(image);
  const dateObject = new Date(date);
  const formattedDate = dateObject.toLocaleDateString('en-ca', { month: 'short', day: 'numeric', year: 'numeric' });
  return (
    <Link to={url} className="t-contents">
      <div className="t-flex t-flex-col t-w-full sm:t-w-1/3 t-px-4 hover:t-shadow-custom t-transform t-transition-all t-scale-100 hover:t-scale-105">
        <GatsbyImage image={gImage} className="t-mb-5" />
        <p className="t-text-blue-300 t-text-xl t-mb-4 t-uppercase">
          {formattedDate}
        </p>
        <p className="t-font-medium t-text-sm t-mb-3">
          {title}
        </p>
      </div>
    </Link>
  )
}
