export const restructureEvent = allSanityEvent => {
  const result = []
  const { nodes } = allSanityEvent;
  nodes.forEach(node => {
    const image = node.image.asset.gatsbyImageData;
    const { title, startTime, endTime, location } = node;
    const url = `/${node.slug.current}/`;
    result.push({
      image, title, startTime, endTime, location, url
    })
  })
  return result;
}

export const restructureUpdate = allSanityUpdate => {
  const result = []
  const { nodes } = allSanityUpdate;
  nodes.forEach(node => {
    const image = node.image.asset.gatsbyImageData;
    const { title, date } = node;
    const url = `/${node.slug.current}/`;
    const sampleText = (node.content || []).reduce((acc, content) => {
      if (acc != "") {
        return acc;
      }

      if (content['children'] && content['children'][0] && content['children'][0]['text']) {
        acc = content['children'][0]['text']
      }
      return acc;
    }, "")
    result.push({
      image, date, url, title, sampleText
    })
  })
  return result;
}
