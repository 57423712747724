import React from 'react';
import { Link } from 'gatsby';
import HouseIcon from '../assets/images/icons/house-icon.svg';
import HatIcon from '../assets/images/icons/hat-icon.svg';
import FeatherIcon from '../assets/images/icons/feather-icon.svg';
import BookIcon from '../assets/images/icons/book-icon.svg';
import Campus from '../assets/images/deran-campus.jpeg';

const data = [
  {
    src: HouseIcon,
    metric: 3,
    description: 'Located in GTA'
  },
  {
    src: HatIcon,
    metric: '100%',
    description: '100% Graduation'
  },
  {
    src: FeatherIcon,
    metric: '50+',
    description: 'Qualified Staffs'
  },
  {
    src: BookIcon,
    metric: '100+',
    description: 'Courses Published'
  }
]

export default function ApplicationSection() {
  return (
    <section className="t-flex t-flex-col t-bg-gray-200 t-w-full">
      <div className="t-flex t-justify-evenly t-py-12">
        {
          data.map(metric => (
            <div key={metric.metric} className="t-flex t-flex-col">
              <img className="t-mb-8 t-w-16 t-h-16" src={metric.src} alt={`${metric.description}`} />
              <p className="t-text-purple-1000 t-font-bold t-text-lg">{metric.metric}</p>
              <div className="t-h-px t-bg-purple-1000 t-border t-border-purple-1000 t-my-3 t-w-16"></div>
              <p>{metric.description}</p>
            </div>
          ))
        }
      </div>
      <div className="t-flex">
        <img src={Campus} className="t-w-1/2" />
        <div className="t-bg-purple-1000 t-w-1/2 t-flex t-flex-col t-py-16 t-px-20">
          <p className="t-text-white t-text-xl t-mb-3">
            Apply for Admission
          </p>
          <p className="t-text-lg t-mb-8 t-text-blue-300">
            Fall 2021 applications are now open
          </p>
          <p className="t-mb-8 t-text-white">
            We don’t just give students an education and experiences that set them up for success in a career. We help them succeed in their career—to discover a field they’re passionate about and dare to lead it.
          </p>
          <Link to="/" className="t-bg-blue-300 t-pl-4 t-flex t-w-max">
            <p className="t-text-lg t-text-purple-1000 t-bg-white t-p-4">
              Apply Now
            </p>
          </Link>
        </div>
      </div>
    </section>
  )
}
